// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---basic-action-mdx": () => import("./../../../../basic/action.mdx" /* webpackChunkName: "component---basic-action-mdx" */),
  "component---basic-action-zh-mdx": () => import("./../../../../basic/action.zh.mdx" /* webpackChunkName: "component---basic-action-zh-mdx" */),
  "component---basic-dependecies-injection-mdx": () => import("./../../../../basic/dependecies_injection.mdx" /* webpackChunkName: "component---basic-dependecies-injection-mdx" */),
  "component---basic-dependecies-injection-zh-mdx": () => import("./../../../../basic/dependecies_injection.zh.mdx" /* webpackChunkName: "component---basic-dependecies-injection-zh-mdx" */),
  "component---basic-effects-mdx": () => import("./../../../../basic/effects.mdx" /* webpackChunkName: "component---basic-effects-mdx" */),
  "component---basic-effects-zh-mdx": () => import("./../../../../basic/effects.zh.mdx" /* webpackChunkName: "component---basic-effects-zh-mdx" */),
  "component---basic-index-mdx": () => import("./../../../../basic/index.mdx" /* webpackChunkName: "component---basic-index-mdx" */),
  "component---basic-index-zh-mdx": () => import("./../../../../basic/index.zh.mdx" /* webpackChunkName: "component---basic-index-zh-mdx" */),
  "component---faq-faq-mdx": () => import("./../../../../faq/FAQ.mdx" /* webpackChunkName: "component---faq-faq-mdx" */),
  "component---faq-faq-zh-mdx": () => import("./../../../../faq/FAQ.zh.mdx" /* webpackChunkName: "component---faq-faq-zh-mdx" */),
  "component---index-mdx": () => import("./../../../../index.mdx" /* webpackChunkName: "component---index-mdx" */),
  "component---recipes-adding-new-module-asynchronously-mdx": () => import("./../../../../recipes/adding_new_module_asynchronously.mdx" /* webpackChunkName: "component---recipes-adding-new-module-asynchronously-mdx" */),
  "component---recipes-adding-new-module-asynchronously-zh-mdx": () => import("./../../../../recipes/adding_new_module_asynchronously.zh.mdx" /* webpackChunkName: "component---recipes-adding-new-module-asynchronously-zh-mdx" */),
  "component---recipes-cancellation-mdx": () => import("./../../../../recipes/cancellation.mdx" /* webpackChunkName: "component---recipes-cancellation-mdx" */),
  "component---recipes-cancellation-zh-mdx": () => import("./../../../../recipes/cancellation.zh.mdx" /* webpackChunkName: "component---recipes-cancellation-zh-mdx" */),
  "component---recipes-dependencies-replacement-mdx": () => import("./../../../../recipes/dependencies_replacement.mdx" /* webpackChunkName: "component---recipes-dependencies-replacement-mdx" */),
  "component---recipes-dependencies-replacement-zh-mdx": () => import("./../../../../recipes/dependencies_replacement.zh.mdx" /* webpackChunkName: "component---recipes-dependencies-replacement-zh-mdx" */),
  "component---recipes-loading-error-state-mdx": () => import("./../../../../recipes/loading_error_state.mdx" /* webpackChunkName: "component---recipes-loading-error-state-mdx" */),
  "component---recipes-loading-error-state-zh-mdx": () => import("./../../../../recipes/loading_error_state.zh.mdx" /* webpackChunkName: "component---recipes-loading-error-state-zh-mdx" */),
  "component---recipes-select-state-mdx": () => import("./../../../../recipes/select-state.mdx" /* webpackChunkName: "component---recipes-select-state-mdx" */),
  "component---recipes-select-state-zh-mdx": () => import("./../../../../recipes/select-state.zh.mdx" /* webpackChunkName: "component---recipes-select-state-zh-mdx" */),
  "component---recipes-writing-tests-mdx": () => import("./../../../../recipes/writing_tests.mdx" /* webpackChunkName: "component---recipes-writing-tests-mdx" */),
  "component---recipes-writing-tests-zh-mdx": () => import("./../../../../recipes/writing_tests.zh.mdx" /* webpackChunkName: "component---recipes-writing-tests-zh-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

